body {
  background-color: #0a0c10;
  color: #ffffff;
  font-family: "proxima-nova", sans-serif;
  margin: 0;
  padding: 0;
}

.login {
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

img {
  width: 300px;
}

.inputRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

Label {
  margin-bottom: 5px;
  font-size: 16px;
}

.login Input {
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  width: 200px;
}

Button {
  background-color: #34869d;
  width: 220px;
  height: 40px;
  border-radius: 10px;
  color: #fff;
  border: 0px;
  cursor: pointer;
  font-size: 18px;
}

Button:hover {
  background-color: #3ca4c1;
}

Button:disabled {
  background-color: #2f383b;
}

.push {
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.push Input {
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  width: 500px;
}

.push Textarea {
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  width: 500px;
  height: 150px;
}

.push Select {
  font-size: 18px;
  padding: 10px;
  border-radius: 10px;
  width: 520px;
}
